<template>
    <div class="case">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">{{$t('shou-ye')}}</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/brandDetail',
                    query: {
                        id: vendorInfo.id
                    }
                }"
                replace
            >
                {{ name }}</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{
                    path: '/brandNews',
                    query: {
                        id: vendorInfo.id
                    }
                }"
                replace
            >
                {{$t('xin-wen-zi-xun')}}</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{$t('zi-xun-xiang-qing')}}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="center">
            <div class="title">
                {{ caseInfo.title }}
            </div>

            <div class="sub">
                {{ caseInfo.introduction }}
            </div>

            <div class="time">{{$t('fa-bu-ri-qi')}}：{{ caseInfo.createdAt }}</div>

            <div class="content" v-html="caseInfo.content"></div>
        </div>
    </div>
</template>

<script>
import { saveStatistics } from '../../utils/CommonApi';
export default {
    name: 'caseDetail',
    props: {
        vendorInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            caseInfo: {},
            caseId: 0
        };
    },
    computed: {
        name() {
            return this.getName(this.vendorInfo, ['chCompanyName', 'enCompanyName']);
        }
    },
    created() {
        this.$http.get('/information/get/' + this.$route.query.id).then(res => {
            this.$emit('setStore', res.vendorInfoId);
            this.caseInfo = res;
            this.caseId = res.id;
            saveStatistics(this.caseId, 'INFORMATION');
        });
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeunloadEvent);
    },
    beforeRouteLeave(from, to, next) {
        saveStatistics(this.caseId, 'INFORMATION', true);
        next();
    },
    methods: {
        beforeunloadEvent(e) {
            saveStatistics(this.caseId, 'INFORMATION', true);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.case {
    @include center-content(470);
    padding-bottom: 50px;
    box-sizing: border-box;

    .el-breadcrumb {
        padding: 30px 0 20px;
    }
}

.center {
    background: #ffffff;
    padding: 40px 50px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        line-height: 33px;
        padding-bottom: 20px;
        // border-bottom: 1px solid #f2f3f5;
        text-align: center;
    }

    .sub {
        font-size: 12px;
        color: #878d99;
        line-height: 22px;
        text-align: center;
    }

    .time {
        font-size: 12px;
        color: #878d99;
        line-height: 17px;
        margin-top: 20px;
        text-align: center;
    }
}

.content {
    border-top: 1px solid #f2f3f5;
    margin-top: 30px;
}
</style>
