var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"case"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v(_vm._s(_vm.$t('shou-ye')))]),_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/brandDetail',
                query: {
                    id: _vm.vendorInfo.id
                }
            },"replace":""}},[_vm._v(" "+_vm._s(_vm.name))]),_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/brandNews',
                query: {
                    id: _vm.vendorInfo.id
                }
            },"replace":""}},[_vm._v(" "+_vm._s(_vm.$t('xin-wen-zi-xun')))]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$t('zi-xun-xiang-qing')))])],1),_c('div',{staticClass:"center"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.caseInfo.title)+" ")]),_c('div',{staticClass:"sub"},[_vm._v(" "+_vm._s(_vm.caseInfo.introduction)+" ")]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.$t('fa-bu-ri-qi'))+"："+_vm._s(_vm.caseInfo.createdAt))]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.caseInfo.content)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }